<right-panel-header
    class="data-collection-right-panel__header"
    objectType="dataset"
    [icon]="details.datasetType | typeToIcon | toModernIcon:24"
>
    {{details.name}}
</right-panel-header>
<div class="data-collection-right-panel__content">
    <right-panel-accordion-section [opened]="true" [showTopBorder]="false">
        <ng-container title>Actions</ng-container>
        <div content class="data-collection-right-panel__actions-container">
            <right-panel-actions-container content>
                <right-panel-action
                    icon="dku-icon-data-table-24"
                    [disabled]="true"
                    disabledTooltip="You don't have the permission to read this dataset"
                >Explore</right-panel-action>

                <right-panel-action
                    icon="dku-icon-arrow-download-24"
                    [disabled]="true"
                    tooltip="Download data or export it"
                    disabledTooltip="You don't have the permission to export data from this project"
                >Export</right-panel-action>

                <right-panel-action-watch-btn
                    [watched]="false"
                    [disabled]="true"
                    disabledTooltip="You cannot mark as favorite a dataset that you cannot read"
                ></right-panel-action-watch-btn>

                <right-panel-action-star-btn
                    [starred]="false"
                    [disabled]="true"
                    disabledTooltip="You cannot watch a dataset that you cannot read"
                ></right-panel-action-star-btn>

                <right-panel-action
                    icon="dku-icon-trash-24"
                    class="action--danger"
                    [disabled]="!currentUserPermissions.write"
                    disabledTooltip="You don't have the rights to remove this item"
                    (clicked)="removeSelectedItem.emit()"
                >Remove</right-panel-action>
            </right-panel-actions-container>

            <div class="data-collection-right-panel__big-actions-buttons">
                <button class="btn btn--secondary"
                    [matTooltip]="'You don\'t have the permission to read datasets from this project'"
                    [disabled]="true"
                >
                    <i class="dku-icon-search-16"></i>
                    <span>Preview</span>
                </button>
                <button class="btn btn--primary"
                    [class.disabled]="shareability === 'nothing'"
                    [attr.aria-disabled]="shareability === 'nothing'"
                    [matTooltip]="shareButtonTooltip"
                    (click)="shareButtonAction()"
                >
                    <i [class]="shareability === 'request' ? 'dku-icon-lock-closed-16' : 'dku-icon-share-16'"></i>
                    <span>{{shareability === 'request' ? 'Request': 'Use'}}</span>
                </button>
            </div>
        </div>
    </right-panel-accordion-section>


    <right-panel-accordion-section [opened]="true">
        <ng-container title>Details</ng-container>
        <div
            content
            class="data-collection-right-panel__details-container"
        >
            <right-panel-details-project
                [projectKey]="details.sourceProjectKey"
                [projectDisplayName]="details.sourceProjectName"
                [objectId]="details.name"
                type="DATASET"
            >
            </right-panel-details-project>
            <right-panel-details-description
                [shortDescription]="details.shortDesc"
                [longDescription]="details.description"
            >
            </right-panel-details-description>
            <right-panel-details-tags
                [tags]="details.tags"
                objectType="DATASET"
            ></right-panel-details-tags>

            <right-panel-details-data-steward
                [dataSteward]="details.dataSteward"
                [isEditable]="false"
            >
            </right-panel-details-data-steward>
        </div>
    </right-panel-accordion-section>
</div>
