import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { fairAny } from 'dku-frontend-core';
import { IHeaderParams } from 'ag-grid-community';
import { ContextOptions, DkuHeaderParams, selectEntireGrid } from '../utils';


type EnrichedHeaderParams = IHeaderParams & DkuHeaderParams;


@Component({
    selector: 'spreadsheet-column-header',
    templateUrl: './spreadsheet-column-header.component.html',
    styleUrls: ['./spreadsheet-column-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpreadsheetColumnHeaderComponent implements IHeaderAngularComp {
    public params!: EnrichedHeaderParams;

    constructor(
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject("CreateModalFromTemplate") private CreateModalFromTemplate: fairAny
    ){}

    agInit(params: EnrichedHeaderParams): void {
        this.params = params;
        // Enable right-click context menu on column headers except for the index column
        if (this.params.column.getColId() !== "0") {
            this.params.eGridHeader.oncontextmenu = ($event) => this.onContextMenu($event);
        }
        // Use a pointer cursor on the whole header cell to tell the user that it is clickable
        this.params.eGridHeader.style.cursor = 'pointer';
        // Make the whole header cell (double-)clickable (otherwise only the title)
        this.params.eGridHeader.onclick = () => this.onClick();
        this.params.eGridHeader.ondblclick = () => this.onDoubleClick();
    }

    refresh(params: EnrichedHeaderParams) {
        this.agInit(params);
        return true;
    }

    onDoubleClick() {
        if (this.params.isIndexCol) {
            return;
        }
        const contextOptions = new ContextOptions(this.params.api, this.params.columnApi, this.params.spreadsheetDiffs);
        contextOptions.updateColumnDef(this.$rootScope.$new(), this.CreateModalFromTemplate, this.params.column);
        this.params.api.clearRangeSelection();
    }

    onContextMenu(event: MouseEvent) {
        event.preventDefault();
        this.selectEntireColumn();
        // See `getMainMenuItems()` of `EditableDatasetSpreadsheetComponent` for the menu items
        this.params.api.showColumnMenuAfterMouseClick(this.params.column, event);
    }

    onClick() {
        // Clear all existing selection first
        this.params.api.clearRangeSelection();
        if (this.params.isIndexCol) {
            // Select the whole table if the click is on index column header
            selectEntireGrid(this.params.api, this.params.columnApi);
        } else {
            // Only select all cells of the target column
            this.selectEntireColumn();
        }
    }

    private selectEntireColumn() {
        this.params.api.clearRangeSelection();
        this.params.api.clearFocusedCell();
        this.params.api.addCellRange({
            rowStartIndex: 0,
            rowEndIndex: this.params.api.getDisplayedRowCount() - 1, // `rowEndIndex` is included in the selection
            columns: [this.params.column]
        });
    }
}
