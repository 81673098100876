import { Directive, ElementRef, Inject, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from 'dku-frontend-core';

/**
 * A directive that allows to display tags.
 * This is not a full upgrade of the angularjs component, which is more complex and has more options.
 * This only contains what is required to display tags for an item.
 */

@Directive({
    selector: 'responsive-tags-list',
})
export class ResponsiveTagsListDirective extends UpgradeComponent implements OnChanges {
    @Input() objectType!: string;
    @Input() tags!: string[];

    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef,
        injector: Injector
    ) {
        super('ng1ResponsiveTagsList', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detection
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
