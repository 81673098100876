<api-error-alert [apiError]="apiError$ | async" [closable]="true" (resetError)="resetApiError()"></api-error-alert>
<div class="editable-dataset-spreadsheet__container">
    <div class="editable-dataset-spreadsheet__controllers-container">
        <div class="children-horizontal-spacing-2x">
            <button title="Undo" class="editable-dataset-spreadsheet__secondary-controller" [ngClass]="canUndo() ? 'active' : 'disabled'" [disabled]="!canUndo()" (click)="undoChange()">
                <i class="dku-icon-arrow-undo-16"></i>
            </button>
            <button title="Redo" class="editable-dataset-spreadsheet__secondary-controller" [ngClass]="canRedo() ? 'active' : 'disabled'" [disabled]="!canRedo()" (click)="redoChange()">
                <i class="dku-icon-arrow-redo-16"></i>
            </button>
            <button (click)="importDataset()" class="btn btn--secondary btn--dku-icon">
                <i class="dku-icon-arrow-curve-down-16"></i>
                Import
            </button>
        </div>
        <div class="editable-dataset-spreadsheet__controllers-container-right">
            <div *ngIf="hasConflictingChanges" class="alert alert-danger">Save failed due to conflicting edits</div>
            <button class="editable-dataset-spreadsheet__main-controller" (click)="save()" [disabled]="!hasUnsavedChanges">
                <i class="dku-icon-save-16"></i>Save<span *ngIf="!hasUnsavedChanges">d!</span>
            </button>
        </div>
    </div>
    <ag-grid-angular
        class="ag-theme-alpine editable-dataset-spreadsheet__grid prevent-mousetrap"
        rowSelection="multiple"
        [stopEditingWhenCellsLoseFocus]="true"
        [defaultColDef]="defaultColDef"
        [suppressRowClickSelection]="true"
        [enableRangeSelection]="true"
        [enableFillHandle]="true"
        [undoRedoCellEditing]="true"
        [suppressDragLeaveHidesColumns]="true"
        [suppressClipboardApi]="true"
        [getContextMenuItems]="getContextMenuItems"
        [getMainMenuItems]="getMainMenuItems"
        [statusBar]="statusBar"
        [enterMovesDownAfterEdit]="true"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (cellKeyDown)="onCellKeyDown($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (cellEditingStarted)="onCellEditingStarted($event)"
        (dragStarted)="onDragStarted($event)"
        (dragStopped)="onDragStopped($event)"
        (cellClicked)="onCellClicked($event)"
        >
    </ag-grid-angular>
</div>
