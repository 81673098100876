<div class="data-collection-card__top-part">
    <div class="data-collection-card__icon">
        <data-collection-icon
            [name]="dataCollection.displayName"
            [color]="dataCollection.color"
            [size]="64"
        ></data-collection-icon>
    </div>
    <div class="data-collection-card__top-right">
        <div class="data-collection-card__name"
            [matTooltip]="dataCollection.displayName" isEllipsisActive
        >{{dataCollection.displayName}}</div>
        <div class="data-collection-card__metadata">
            <div>
                <i class="dku-icon-dataset-16"></i>
                <span>{{ dataCollection.itemCount }} {{'dataset' | plurify:dataCollection.itemCount}}</span>
            </div>
            <div>
                <i class="dku-icon-clock-16"></i>
                <span>{{ dataCollection.lastModifiedOn | friendlyTimeDeltaShort }}</span>
            </div>
        </div>
        <div class="data-collection-card__tags">
            <i class="dku-icon-tag-16"></i>
            <responsive-tags-list
                *ngIf="dataCollection.tags.length > 0"
                [tags]="dataCollection.tags"
                objectType="DATA_COLLECTION"
            ></responsive-tags-list>
            <span *ngIf="dataCollection.tags.length === 0">
                No tags have been assigned
            </span>
        </div>
        
    </div>
</div>
<div class="data-collection-card__description"
    [class.data-collection-card__description--empty]="dataCollection.description === ''"
>
    <markdown #description
        [from]="dataCollection.description"
    ></markdown>
</div>