import { ColDef, GridApi, Column, ColGroupDef, IRowNode, ColumnApi } from 'ag-grid-community';
import { SpreadsheetDiffs, AddCols, AddRows, RemoveRows, RemoveCols, UpdateColDef } from './spreadsheet-diffs';
import { fairAny } from 'dku-frontend-core';
import { EditableDatasetService, SchemaColumn, Schema, VersionTag } from 'src/generated-sources';


// Row representation in the UI, stored in the data property of AG Grid's RowNode
export type CustomRowData = {
    initIndex: number,
    dirty: boolean,
    data: Record<string, string | null>
};


export type DkuHeaderParams = {
    spreadsheetDiffs: SpreadsheetDiffs,
    schemaColumn? : Partial<SchemaColumn>,
    markChanged: boolean,
    isIndexCol: boolean,
};

// Column representation in the UI
export interface WellDefinedColDef extends ColDef {
    field: string,
    headerName: string,
    headerComponentParams: DkuHeaderParams
}

export const colNameEncoder = (name: string) => name.replace(/[_]/g, '__dku_underscore_encoder__').replace(/[.]/g, '__dku_point_encoder__');
export const colNameDecoder = (name: string) => name.replace(/__dku_point_encoder__/g, ".").replace(/__dku_underscore_encoder__/g, "_");
export const customRowAccessorToNodeAccessor = (customRowAccessor: string) => "data." + customRowAccessor;
export const nodeAccessorToCustomRowAccessor = (nodeAccessor: string) => nodeAccessor.replace('data.', ''); // only first occurence of 'data'

// Switch from AG Grid representation of rows to UI's
export const colNameToField = (colName: string) => customRowAccessorToNodeAccessor(colNameEncoder(colName));
export const fieldToColName = (field: string) => colNameDecoder(nodeAccessorToCustomRowAccessor(field));

// content of the grid if the received dataset is empty
const BASE_COLUMN_NAME = 'new_column_0';
const NEW_COLUMN_PREFIX = 'new_column';
export const generateBaseColDef: (spreadsheetDiffs: SpreadsheetDiffs) => WellDefinedColDef = (spreadsheetDiffs) => {
    return {
        field: colNameToField(BASE_COLUMN_NAME),
        headerName: BASE_COLUMN_NAME,
        headerComponentParams: {
            spreadsheetDiffs: spreadsheetDiffs,
            schemaColumn: {
                name: BASE_COLUMN_NAME,
                type: 'string',
                comment: undefined,
            },
            markChanged: false,
            isIndexCol: false
        }
    };
};

export const generateBaseRowData: () => CustomRowData = () => ({
    initIndex: -1,
    dirty: true,
    data: {
        [colNameEncoder(BASE_COLUMN_NAME)]: null
    }
});

export enum RowSide {
    ABOVE,
    BELOW
}

export enum ColSide {
    LEFT,
    RIGHT
}

export class ContextOptions {

    constructor(private gridApi: GridApi, private columnApi: ColumnApi, private spreadsheetDiffs: SpreadsheetDiffs) {}

    /**
     * When building a new column, finds the next x such that new_column_{x} is not used and new_column_{x - 1} is used
     * Can be applied for different attributes of a column : headerName, and field usually. Because they are not necessarily
     * the same but answers to the same naming rules.
     */
    private static getSuffix(allColDefs: WellDefinedColDef[], getter: (col: WellDefinedColDef) => string): number {
        const usedSuffixes = allColDefs
                .map(getter)
                .filter(value => value.startsWith(NEW_COLUMN_PREFIX))
                .map(value => Number(value.split("_")[2]))
                .filter(x => !isNaN(x));
        return usedSuffixes.length ? Math.max(...usedSuffixes) + 1 : 0;
    }

    /**
     * checks if the modifications of the column defintion can be submitted to AG Grid.
     * Namely we check that :
     *  - the name is defined
     *  - the name is not an empty string
     *  - the name is not already used
     */
    private static isValid(newColName: string, oldColName: string | undefined, allColDefs: (ColDef | ColGroupDef)[] | undefined): boolean {
        if(newColName === undefined || newColName.length === 0) return false;
        if(allColDefs) {
            const allColumnFields = allColDefs.map((colDef: ColDef) => colDef.headerName);
            if(allColumnFields.indexOf(newColName) >= 0 && newColName !== oldColName) {
                return false;
            }
        }
        return true;
    }

    /**
     * Close the modal
     */
    private static closeModal(): void {
        const removeContainer = (id: string) => {
            const modalQueryRes = document.querySelector(id);
            if(modalQueryRes) {
                modalQueryRes.remove();
            }
        };
        removeContainer('.modal-container');
        removeContainer('.modal-backdrop');
    }

    /**
     * Add the given number of columns on the given side of the given col index
     */
    addColumns(colIndex: number | null, side: ColSide, nbCols: number, deletedColDefs: WellDefinedColDef[]) {
        if ( !colIndex ) return;
        if ( !this.gridApi.getColumnDefs() ) return;

        const allColDefs = (this.gridApi.getColumnDefs() as (WellDefinedColDef)[]).slice(1); // slice(1) to exclude index column
        const firstNewHeaderNameSuffix = ContextOptions.getSuffix(allColDefs, col => col.headerName);
        const firstNewNameSuffix = ContextOptions.getSuffix(allColDefs.concat(deletedColDefs), col => fieldToColName(col.field));

        const sideAdder = side === ColSide.RIGHT ? 1 : 0;

        const newColDefs = Array(nbCols);
        const newPositions = Array(nbCols);
        for (let i = 0; i < nbCols; i++) {
            const newHeaderName = `${NEW_COLUMN_PREFIX}_${firstNewHeaderNameSuffix + i}`;
            const newName = `${NEW_COLUMN_PREFIX}_${firstNewNameSuffix + i}`;
            newColDefs[i] = {
                field : colNameToField(newName),
                headerName: newHeaderName,
                headerComponentParams: {
                    schemaColumn: {
                        name: newHeaderName,
                        type: 'string',
                        comment: undefined
                    },
                    spreadsheetDiffs: this.spreadsheetDiffs,
                    markChanged: false,
                    isIndexCol: false,
                }
            };
            newPositions[i] = colIndex + sideAdder + i;
        }

        this.spreadsheetDiffs.registerAndExecute(new AddCols(newColDefs, newPositions, this.gridApi));
    }

    /**
     * Add the given number of rows on the given side of the given row index
     */
    addRows(rowIndex: number | null, side: RowSide, nbRows: number) {
        if ( rowIndex == null ) return;
        const newRowData: CustomRowData[] = Array(nbRows);
        // To fill the array of new rows, we use the good old for loop instead of fill() because fill() always re-uses the same object passed as argument (even when it's empty) and doesn't create new ones
        // We want a new object (meaning new variable reference / address) each time because AG Grid uses the object's reference / address as ID, to remove the right rows for example
        for (let i = 0; i < newRowData.length; i++) {
            newRowData[i] = {
                initIndex: -1,
                dirty: true,
                data: {}
            };
        }
        const sideAdder = side === RowSide.ABOVE ? 0 : 1;
        this.spreadsheetDiffs.registerAndExecute(new AddRows(newRowData, rowIndex + sideAdder, this.gridApi));
    }

    /**
     * Delete the given columns
     */
    deleteColumns(columns: Column[] | null): WellDefinedColDef[] {
        if ( !columns ) return [];

        const allColFields = (this.gridApi.getColumnDefs() as WellDefinedColDef[]).map(col => col.field);
        const totalColCountBeforeExecution = allColFields.length;

        const toDeleteColDefs = columns.map(col => col.getColDef()) as WellDefinedColDef[];
        const toDeletePositions = toDeleteColDefs.map(colDef => allColFields.indexOf(colDef.field));
        this.spreadsheetDiffs.registerAndExecute(new RemoveCols(toDeleteColDefs, toDeletePositions, this.gridApi));

        // Handle the case where every column was deleted
        // +1 since there is also the index column to take into account
        if (columns.length + 1 === totalColCountBeforeExecution) {
            const allRowsData: CustomRowData[] = [];
            this.gridApi.forEachNode(rowNode => allRowsData.push(rowNode.data));
            this.spreadsheetDiffs.registerAndExecute(
                // First remove all the remaining rows. They mean nothing without a column
                // The number of rows stay the same because we have a custom protected column: the index column
                new RemoveRows(allRowsData, 0, this.gridApi)
                // Add an empty default column at position 1 since the index column is always at position 0
                .combine(new AddCols([generateBaseColDef(this.spreadsheetDiffs)], [1], this.gridApi)
                // Add an empty default row at position 0 since all rows has been deleted
                .combine(new AddRows([generateBaseRowData()], 0, this.gridApi))
                )
            );
        }

        return toDeleteColDefs;
    }

    /**
     * Delete the given rows
     */
    deleteRows(rows: IRowNode[] | null) {
        if ( !rows ) return;
        const totalRowCountBeforeExecution = this.gridApi.getDisplayedRowCount();

        // We must have 1 RemoveRows diff per contiguous range of rows
        rows.sort((rowA, rowB) => {
            if (rowA.rowIndex == null && rowB.rowIndex == null) return 0;
            if (rowA.rowIndex == null) return 1;
            if (rowB.rowIndex == null) return -1;
            return rowA.rowIndex - rowB.rowIndex;
        });
        const removeRowsDiffs: RemoveRows[] = [];
        let currentContiguousRowData: CustomRowData[] = [];
        let currentMinRowIndex: number = -1;
        let prevRowIndex: number = -1;
        for (const row of rows) {
            if (row.rowIndex == null) return;
            if (prevRowIndex === -1) {
                // Init
                currentContiguousRowData.push(row.data);
                currentMinRowIndex = row.rowIndex;
            } else if (row.rowIndex === prevRowIndex + 1) {
                // Still contiguous
                currentContiguousRowData.push(row.data);
            } else {
                // New contiguous range
                removeRowsDiffs.push(new RemoveRows(currentContiguousRowData, currentMinRowIndex, this.gridApi));
                currentContiguousRowData = [row.data];
                currentMinRowIndex = row.rowIndex;
            }
            prevRowIndex = row.rowIndex;
        }
        // Handle the last contiguous range
        removeRowsDiffs.push(new RemoveRows(currentContiguousRowData, currentMinRowIndex, this.gridApi));
        // Register (and execute) all RemoveRows diffs at once to be sure they will be in the same multi diff
        removeRowsDiffs.reverse().forEach(removeRowsDiff => this.spreadsheetDiffs.registerAndExecute(removeRowsDiff));

        // Handle the case where every row is deleted
        if (totalRowCountBeforeExecution === rows.length) {
            // Add an empty default row
            this.spreadsheetDiffs.registerAndExecute(new AddRows([generateBaseRowData()], 0, this.gridApi));
        }
    }

    /**
     * Delete the rows that are completely empty i.e. where every column is empty
     */
    deleteEmptyRows() {
        const emptyRows: IRowNode[] = [];
        this.gridApi.forEachNode(row => {
            const isEveryColEmpty = Object.values(row.data.data).every(val => !val);
            if (isEveryColEmpty) {
                emptyRows.push(row);
            }
        });
        this.deleteRows(emptyRows);
    }

    /**
     * Tries to update the column definition with the values entered in the modal
     */
    updateColumnDef(scope: fairAny, CreateModalFromTemplate: fairAny, col: Column): void {
        const colDef = col.getColDef() as WellDefinedColDef;

        scope.column = {
            name : colDef.headerName,
            ...JSON.parse(JSON.stringify(colDef.headerComponentParams.schemaColumn)),
        };

        scope.isValid = () => ContextOptions.isValid(scope.column.name, colDef.headerName, this.gridApi.getColumnDefs());
        scope.validate = () => {
            this.validate(colDef, scope.column);
            ContextOptions.closeModal();
        };
        scope.removeCol = () => {
            this.deleteColumns([col]);
            ContextOptions.closeModal();
        };

        CreateModalFromTemplate("/templates/datasets/editable-dataset-column-modal.html", scope);
    }

    autoSizeAllColumns() {
        this.columnApi.autoSizeColumns((this.columnApi.getColumns() ?? []).slice(1)); // do not auto size index column
    }

    /**
     * Submits the column modifications to AG Grid
     */
    private validate = (oldColDef: WellDefinedColDef, newLegacyColumn: fairAny): void => {
        const hasUpdate =
            newLegacyColumn.name !== oldColDef.headerName
            || JSON.stringify(newLegacyColumn) !== JSON.stringify(oldColDef.headerComponentParams.schemaColumn);

        if (!hasUpdate) return;
        if (!ContextOptions.isValid(newLegacyColumn.name, oldColDef.headerName, this.gridApi.getColumnDefs())) return;

        // Create a brand new col def object containing the updates from the legacy column object and the existing column schema of the old column definition
        // This is mandatory for undo/redo to work correctly
        const newColDef: WellDefinedColDef = {
            ...oldColDef,
            headerName: newLegacyColumn.name,
            headerComponentParams: {
                ...oldColDef.headerComponentParams,
                schemaColumn: {
                    ...oldColDef.headerComponentParams.schemaColumn,
                    ...newLegacyColumn
                }
            }
        };
        this.spreadsheetDiffs.registerAndExecute(new UpdateColDef(oldColDef, newColDef, this.gridApi));
    };
}

export class DatasetBuilder {

    static buildDataset(colDefs: WellDefinedColDef[], currentVersionTag: VersionTag, rowIterator: (callback: ((rowData: CustomRowData) => void)) => void) {

        /** BUILDING THE COLUMNS */
        let colMappingChanged = false;
        const colMapping: string[] = [];
        // Remove the first column which is the index column
        const columnsFull = colDefs.slice(1).map(col => {

                if(col.field !== colNameToField(col.headerName) || col.headerComponentParams.markChanged) {
                    colMappingChanged = true;
                }
                const originalColName = fieldToColName(col.field);
                colMapping.push(originalColName);
                return {
                    name: col.headerName,
                    schemaColumn: col.headerComponentParams.schemaColumn,
                    field: col.field
                };
            });

        /** BUILDING THE ROWS */
        const data: (string | null)[][] = [];
        const rowMapping: number[] = [];
        let rowMappingChanged = false;
        let predIndex = -1;
        rowIterator((rowData: CustomRowData) => {
            rowMapping.push(rowData.initIndex);
            rowMappingChanged ||= rowData.initIndex !== predIndex + 1;
            predIndex = rowData.initIndex;

            data.push(rowData.dirty ? columnsFull.map(col => rowData.data[nodeAccessorToCustomRowAccessor(col.field)]) : []);
        });

        const columns = columnsFull.map(col => col.schemaColumn);

        const datasetSchema = ({
            columns: (columns as SchemaColumn[])
        }) as Schema;

        const dataset: EditableDatasetService.EditableDatasetSaveQuery = ({
            data: data,
            schema: datasetSchema,
            versionTag: currentVersionTag,
            rowMapping: rowMappingChanged ? rowMapping : null,
            colMapping: colMappingChanged ? colMapping : null
        });

        return dataset;
    }
}

const SELECT_BIG_GRID_THRESHOLD_NB_CELLS = 100000;

export function selectEntireGrid(gridApi: GridApi, columnApi: ColumnApi) {
    const nbRows = gridApi.getDisplayedRowCount();
    const nbColumns = columnApi.getColumns()?.length ?? 0;
    const nbCells = nbRows * nbColumns;
    if (nbCells > SELECT_BIG_GRID_THRESHOLD_NB_CELLS) {
        // Display a loading overlay for big grid because selection may take a long time
        gridApi.showLoadingOverlay();
        setTimeout(() => {
            _selectEntireGrid(gridApi, columnApi);
            gridApi.hideOverlay();
        }, 15); // setTimeout 15 necessary to be sure the loading overlay is displayed before starting the long operation
    } else {
        _selectEntireGrid(gridApi, columnApi);
    }
}

function _selectEntireGrid(gridApi: GridApi, columnApi: ColumnApi) {
    gridApi.clearRangeSelection();
    gridApi.clearFocusedCell();
    gridApi.addCellRange({
        rowStartIndex: 0,
        rowEndIndex: gridApi.getDisplayedRowCount() - 1,
        columns: (columnApi.getColumns() ?? []).slice(1) // do not select the index column
    });
}
